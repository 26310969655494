import React from 'react'
import logo_dark from '../../assets/images/logo-dark.png';
import logo_light from '../../assets/images/eduvsat.png';

import '../../assets/libs/@mdi/font/css/materialdesignicons.min.css';
import '../../assets/css/tailwind.css';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import * as Icon from 'react-feather';

export default function Navbar(props) {
    let { navClass, navJustify } = props;
    let [isMenu, setisMenu] = useState(false);
    let [manu , setManu] = useState('');
    let location = useLocation();
    
    useEffect(()=>{
        let current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)

        function windowScroll() {
            const navbar = document.getElementById("topnav");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                if (navbar !== null) {
                    navbar?.classList.add("nav-sticky");
                }
            } else {
                if (navbar !== null) {
                    navbar?.classList.remove("nav-sticky");
                }
            }
        }
        window.addEventListener("scroll", windowScroll);
        window.scrollTo(0, 0)
        return () => {
            window.removeEventListener('scroll', windowScroll);
        };

    },[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)])
    

    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };


    return (
        <nav id="topnav" className={`defaultscroll ${navClass === "nav-light" ? '' : navClass === "nav-sticky" ?
        'bg-white dark:bg-slate-900' : ''}`}>
        <div className="container relative">
            {
                navClass === "nav-light" ?
                    <Link className="logo" to="/index">
                        <span className="inline-block dark:hidden">
                            <img src={logo_light} className="l-dark" height="22" alt="" />
                            <img src={logo_light} className="l-light" height="22" alt="" />
                        </span>
                        <img src={logo_light} height="20" className="hidden dark:inline-block" alt="" />
                    </Link>
                    :
                    <Link className="logo" to="/index">
                        <img src={logo_light} className="inline-block dark:hidden" alt="" />
                        <img src={logo_light} className="hidden dark:inline-block" alt="" />
                    </Link>
            }

            <div className="menu-extras">
                <div className="menu-item">
                    <Link to="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                        <div className="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </Link>
                </div>
            </div>

            
            <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }} className='d-flex'>
                <ul className={`float-right navigation-menu ${navClass} ${navJustify} `}>
                    {/* <li className={manu === "index" || "" ? "active" : ""}><Link to="/product" className="sub-menu-item">Product</Link></li> */}
                    
                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="">FEATURES</Link>
                        {/* <span className="menu-arrow"></span> */}
                       
                    </li>
                    
                   

                    <li className={`${["portfolio-modern-two", "portfolio-modern-three","portfolio-modern-four","portfolio-modern-five","portfolio-modern-six","portfolio-classic-two","portfolio-classic-three","portfolio-classic-four","portfolio-classic-five","portfolio-classic-six","portfolio-creative-two","portfolio-creative-three","portfolio-creative-four","portfolio-creative-five","portfolio-creative-six","portfolio-masonry-two","portfolio-masonry-three","portfolio-masonry-four","portfolio-masonry-five","portfolio-masonry-six","portfolio-detail-one","portfolio-detail-two","portfolio-detail-three",'portfolio-detail-four'].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item what-we-do-menu-hover static`} >
                        <Link to="/pricing">HELP CENTER</Link>
                      
                    </li>
            
                </ul>
            </div>
        </div >
    </nav >
    )
}
