import React from "react";
import logo from '../assets/images/client/videokrypt.png';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import './styles.css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
export default function CompanyLogo(){
    return (
        <>
          <Swiper
                slidesPerView={3}
                spaceBetween={10}
                centeredSlides={false}
                autoplay={{
                delay: 3500,
                disableOnInteraction: true,
                }}

                breakpoints={{

                    320: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 50,
                    },
                  }}
                pagination={{
                clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
            <SwiperSlide>
                <img src='images/offline/img2.jpg' className="radius-12" alt="" />
            </SwiperSlide>

            <SwiperSlide>
                <img src='images/offline/img7.jpg' className="radius-12" alt="" />
            </SwiperSlide>

            <SwiperSlide>
                <img src='images/offline/img4.jpg' className="radius-12" alt="" />
            </SwiperSlide>

            <SwiperSlide>
                <img src='images/offline/img5.jpg' className="radius-12" alt="" />
            </SwiperSlide>

            <SwiperSlide>
                <img src='images/offline/img6.jpg' className="radius-12" alt="" />
            </SwiperSlide>

            <SwiperSlide>
                <img src='images/offline/img7.jpg' className="radius-12" alt="" />
            </SwiperSlide>

            <SwiperSlide>
                <img src='images/offline/img8.jpg' className="radius-12" alt="" />
            </SwiperSlide>


          </Swiper>
        </>
      );
}