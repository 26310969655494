import React from "react";
import { Link } from "react-router-dom";
// import logo_light from '../../assets/images/blurb-light.png';
import {FaRegEnvelope,FaDribbble,FaLinkedin, FaFacebookF, FaInstagram, FaTwitter,FaRegFile,FaBehance, PiShoppingCart, MdKeyboardArrowRight} from '../../assets/icons/icons'
import logo_light from '../../assets/images/eduvsat.png';
import product1 from '../../assets/images/logo.png';
import product2 from '../../assets/images/edumix.png';
import product3 from '../../assets/images/eduvsat.png';
import product4 from '../../assets/images/interactive.png';
// import product5 from '../../assets/images/eduvsat.png';

export default function JobFooter(){
    return(
        <footer className="footer  relative text-gray-200 dark:text-gray-200">
            <hr className="mt-3"/>
            <div className="container relative text-center">
                <div className="grid grid-cols-1">
                    <div className="">
                        <div className="grid md:grid-cols-2 items-center">
                            <div className="md:text-start text-center">
                                {/* <img src="../assets/images/Blurb.png'"></img> */}
                                <img src={logo_light} alt="" className='width20 mt-2'/>

                                <p className="mb-0 text-black mt-2">All Rights Reserved © 2024 Eduvsat Software Private Limited</p>
                                   {/* <ul className="list-none mt-5 space-x-1 space-y-1">
                                 
                                    <li className="inline"><Link to="#" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaLinkedin className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="#" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaFacebookF className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="#" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaInstagram className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="#" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaTwitter className='text-sm'/></Link></li>
                                
                                </ul> */}
                            </div>

                            <div>
                                <p className="mb-0 text-black mt-2 text-end product-text">Our Products</p>
                                <div className="d-flex justify-content-end mt-2">
                                    <img src={product1} alt="" className='ms-2 mt-5 edukrypt-logo footer-logoes '/>
                                    <img src={product2} alt="" className='ms-2 edumix-logo footer-logoes '/>
                                    <img src={product3} alt="" className='ms-2 mt-2 eduvsat-logo footer-logoes '/>
                                    <img src={product4} alt="" className='ms-2 mt-4 interactive-logo footer-logoes '/>
                                </div>
                            </div>
                        </div>
                    </div>
                  

                </div>
            </div>
            <hr className="mt-3"/>
            <p className="mb-0 text-black mt-5 text-center pb-4">All Rights Reserved © 2024 Eduvsat Software Private Limited</p>

            {/* <div className="py-[30px] px-0 border-t border-slate-800">
                <div className="container relative text-center">
                    <div className="grid grid-cols-1">
                        <div className="text-center">
                            <p className="mb-0">©  Techwind. Design & Develop with <i className="mdi mdi-heart text-red-600"></i> by <Link to="https://shreethemes.in/" target="_blank"className="text-reset">Shreethemes</Link>.</p>
                        </div>
                    </div>
                </div>
            </div> */}
        </footer>
    )
}