import React from 'react'
import { Link } from 'react-router-dom';

import logo_light from '../../assets/images/blurb-light.png';

import american_ex from '../../assets/images/payments/american-ex.png';
import discover from '../../assets/images/payments/discover.png';
import master_card from '../../assets/images/payments/master-card.png';
import paypal from '../../assets/images/payments/paypal.png';
import visa from '../../assets/images/payments/visa.png';

import * as Icon from 'react-feather';

import { footerLinks, footerCompany } from '../../data/data';

import {FaRegEnvelope,FaDribbble,FaLinkedin, FaFacebookF, FaInstagram, FaTwitter,FaRegFile,FaBehance, PiShoppingCart, MdKeyboardArrowRight} from '../../assets/icons/icons'

export default function Footer() {
    return (

<footer className="footer">
<div className="pt-2 pb-6 footer-bar" >
    <div className="container text-center">
        <div className="row align-items-center">
            <div className="col-sm-6">
                <img src="{{asset('public/assets/images/logo/blurb.png')}}" alt="" />
                <p className="mb-0 text-black text-sm mt-2" >All Rights Reserved  © 2024 Blurb Software Private Limited </p>
            </div>

            <div className="col-sm-6">
                <div className="text-sm-start">
                    <p>Our Products </p>
                    <div className='d-flex'>
                    <img src="{{asset('public/assets/images/logo/logo.png')}}" alt="" className='ms-2' />
                    <img src="{{asset('public/assets/images/logo/edumix.png')}}" alt="" className='ms-2' />
                    <img src="{{asset('public/assets/images/logo/eduvsat.png')}}" alt="" className='ms-2'/>
                    <img src="{{asset('public/assets/images/logo/interactive.png')}}" alt=""  className='ms-2'/>
                    </div>
                    
                </div>
            </div>
        </div>
        
    </div>
    <hr/>
        <p className="mb-0 text-black text-sm" ><a href="{{asset('privacy')}}">Privacy Policy</a>  | <a href="{{asset('terms')}}">Terms & Conditions</a> </p>
</div>
</footer>

    
    )
}
