import React,{useEffect,useState} from 'react';

import { Link } from 'react-router-dom';


import encryptionIcon from '../../assets/images/client/encryptionIcon.png';


import '../../../node_modules/tiny-slider/dist/tiny-slider.css'

import Navbar from '../../component/Navbar/navbar';
import JobFooter from '../../component/Footer/jobFooter';

import CookieModal from '../../component/cookieModal';
import CompanyLogo from '../../component/companyLogo';


import {FaRegEnvelope, BiBookAlt, BsCheckCircle, MdKeyboardArrowRight} from '../../assets/icons/icons'


import { accordionData } from '../../data/dataTwo';





const settings = {
  container: '.tiny-two-item',
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  controlsText: ['<i className="mdi mdi-chevron-left"></i>', '<i className="mdi mdi-chevron-right"></i>'],
  nav: false,
  speed: 400,
  gutter: 0,
  responsive: {
    768: {
      items: 2
    },
  },
}
export default function Index() {
  let [isOpen, setOpen] = useState(false)
  let [activeIndex, setActiveIndex] = useState(0);
  let toggleAccordion = (index) => {
    if (activeIndex === index) {
        setActiveIndex(0);
    } else {
        setActiveIndex(index);
    }
}
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
  }, []);

 
  return (
    <div >

      <Navbar />

      <section className="relative table w-full py-36 videokrypt_banner">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="md:col-span-6">
              <div className="me-6">
                <h1 className="font-semibold lg:leading-normal leading-normal text-4xl heading lg:text-5xl mb-5 text-black dark:text-white">Live interactive broadcasting  software for <span className="text-indigo-600"> multiple usage</span></h1>
                <p className="text-slate-400 text-lg max-w-xl text-justify">Let success motivate you.</p>

                <div className="mt-6">
                  <Link to="" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2"><FaRegEnvelope  className="me-2 text-sm "/> Get Started</Link>
                  {/* <Link to="/documentation" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md mt-2"><BiBookAlt className="me-2"/> Documentation</Link> */}
                </div>
              </div>
            </div>

            <div className="md:col-span-6">
              <img src='images/index/banner.png' alt="" />
            </div>
          </div>
        </div>
      </section>

                <div className="container relative mt-24">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        {/* <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Features</h6> */}
                        <h2 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Our Broadcasting Software </h2>

                        <p className="text-slate-400  mx-auto">Facilitate remote learning and e-learning with</p>
                    </div>

                    <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px]">
                        <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
                            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                  <img src='images/index/icons/broadcasting.png' alt="" className='appIcon'/>
                            </div>

                            <div className="content mt-7">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Broadcast live</Link>
                                <p className="text-slate-400 mt-3">Allows educators or instructors to deliver real-time lectures, presentations, or workshops to a live audience.</p>

                                {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
                            </div>
                        </div>

                        <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
                            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                  <img src='images/index/icons/studio.png' alt="" className='appIcon'/>
                            </div>

                            <div className="content mt-7">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Multiple studios</Link>
                                <p className="text-slate-400 mt-3">For creating diverse and engaging virtual classrooms or online learning experiences with multiple studio.</p>

                                {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
                            </div>
                        </div>

                        <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out">
                            <div className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                  <img src='images/index/icons/fully.png' alt="" className='appIcon'/>
                            </div>

                            <div className="content mt-7">
                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">Fully interactive</Link>
                                <p className="text-slate-400 mt-3">Real-time, two-way communication and interaction between the presenter or educator and the audience or students.</p>

                                {/* <div className="mt-5">
                                    <Link to="#" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <div className="ms-2 text-[10px]"/></Link>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>  


            <section className="relative md:py-24 py-16 overflow-hidden">
                <div className="container relative">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                        <div className="relative order-1 md:order-2">
                            <img src='images/index/aboutus.jpg' className="mx-auto " alt="" />
                            <div className="overflow-hidden absolute lg:size-[400px] size-[320px] bg-indigo-600/5 bottom-2/4 translate-y-2/4 end-0 rotate-45 -z-1 shadow-md shadow-indigo-600/10 rounded-[100px]"></div>
                        </div>

                        <div className="lg:me-8 order-2 md:order-1">
                            <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('../../assets/images/map.png')] bg-no-repeat bg-center bg-cover"></div>

                            <h2 className="mb-4 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium">A new era of interactive education with our cutting-edge tech.</h2>
                            <p className="text-slate-400 text-justify">Engage and inspire students from anywhere in the world</p>
                            <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                                <div className='mt-6'>
                                    <h4 className="mb-2 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium text-indigo-600">100%</h4>
                                    <span class="title h5 text-lg font-medium hover:text-indigo-600">Secure Broadcasting</span>
                                    <p className="text-slate-400 text-justify">Ensure the privacy and security of your live broadcasts with encryption options.</p>
                                </div>


                                <div className='mt-6'>
                                    <h4 className="mb-2 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium text-indigo-600">100%</h4>
                                    <span class="title h5 text-lg font-medium hover:text-indigo-600">Secure Broadcasting</span>
                                    <p className="text-slate-400 text-justify">Ensure the privacy and security of your live broadcasts with encryption options.</p>
                                </div>
                            </div>
                          
                           
                            {/* <div className="mt-4">
                                <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl ms-1"/></Link>
                            </div> */}
                        </div>
                    </div>
                </div>   

                     
            </section>


            <section className="relative  bg-gray-50 py-4 dark:bg-slate-800 pb-16">
                <div className="container relative  pt-16">
                    <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('../../assets/images/map.png')] bg-no-repeat bg-center bg-cover"></div>

                    <div className="relative grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Fortify Your Educational Platform</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Secure, Engaging, and Effective Learning Environment with Cutting-Edge Encryption for Ultimate Content Security</p>
                    </div>

                    <div className="relative grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                            <div  className="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
                                <img src='images/index/flexible.png' alt="" className='appIcon'/>
                                <div className="content mt-6">
                                    <Link className="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out">High Quality Audio/Video</Link>
                                    <p className="text-slate-400 mt-4">Immerse students in crystal-clear, HD audio and video for a captivating educational journey.</p>

                                    <div className="mt-5">
                                        {/* <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More<a className="ms-2 text-[10px]"/></Link> */}
                                    </div>
                                </div>
                            </div>

                            <div  className="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
                            <img src='images/index/flexible.png' alt="" className='appIcon'/>
                                <div className="content mt-6">
                                    <Link className="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out">Multiple Studio  </Link>
                                    <p className="text-slate-400 mt-4">Create diverse virtual environments for your broadcasts, enhancing engagement and visual appeal.</p>

                                    <div className="mt-5">
                                        {/* <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More<a className="ms-2 text-[10px]"/></Link> */}
                                    </div>
                                </div>
                            </div>

                            <div  className="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
                                <img src='images/index/flexible.png' alt="" className='appIcon'/>
                                <div className="content mt-6">
                                    <Link className="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out">Multiple Locations </Link>
                                    <p className="text-slate-400 mt-4">Connect with learners from different corners of the globe, fostering a global classroom experience.</p>

                                    <div className="mt-5">
                                        {/* <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More<a className="ms-2 text-[10px]"/></Link> */}
                                    </div>
                                </div>
                            </div>

                            <div  className="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
                                <img src='images/index/flexible.png' alt="" className='appIcon'/>
                                <div className="content mt-6">
                                    <Link className="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out">Low Bandwidth</Link>
                                    <p className="text-slate-400 mt-4">Reach even remote areas with low bandwidth requirements, ensuring access to quality education for all.</p>

                                    <div className="mt-5">
                                        {/* <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More<a className="ms-2 text-[10px]"/></Link> */}
                                    </div>
                                </div>
                            </div>

                            <div  className="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
                                <img src='images/index/flexible.png' alt="" className='appIcon'/>
                                <div className="content mt-6">
                                    <Link className="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out">Hand Raise Option</Link>
                                    <p className="text-slate-400 mt-4">Encourage participation and interaction with the hand raise feature, promoting active learning.</p>

                                    <div className="mt-5">
                                        {/* <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More<a className="ms-2 text-[10px]"/></Link> */}
                                    </div>
                                </div>
                            </div>

                            <div  className="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
                                <img src='images/index/flexible.png' alt="" className='appIcon'/>
                                <div className="content mt-6">
                                    <Link className="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out">Chat</Link>
                                    <p className="text-slate-400 mt-4">Facilitate real-time discussions and Q&A sessions, fostering dynamic communication between educators and learners.</p>

                                    <div className="mt-5">
                                        {/* <Link className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More<a className="ms-2 text-[10px]"/></Link> */}
                                    </div>
                                </div>
                            </div>
                          
                    </div>
                </div>

{/* 
              <div className="container relative md:mt-24 mt-24">

                
              <div className="relative grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Why Choose Us?</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Our integrated suite of services combines cutting-edge video encryption technology with robust LMS enhanced by DRM capabilities.</p>
                    </div>
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
                    <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('../../assets/images/map.png')] bg-no-repeat bg-center bg-cover"></div>

                        <div className="lg:col-span-6">
                            <div className="lg:me-8">
                                <img src='images/Analytics1.png' alt="" />
                            </div>
                        </div>
                        <div className="lg:col-span-6">
                            <div className="grid grid-cols-1 gap-[30px]">
                                <div  className="group flex items-center relative overflow-hidden p-6 rounded-md shadow dark:shadow-gray-800 bg-gray-50 dark:bg-slate-800 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out bg-white">
                                    <span className="text-indigo-600 group-hover:text-white text-5xl font-semibold transition-all duration-500 ease-in-out">
                                        <a className='size-10'/>
                                    </span>
                                    <div className="flex-1 ms-3">
                                        <h5 className="group-hover:text-white text-xl font-semibold transition-all duration-500 ease-in-out">User-Friendly LMS</h5>
                                        <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-2">Our platform is designed to enhance the learning experience for both educators and students. </p>
                                    </div>
                                    <div className="absolute start-1 top-5 text-dark/[0.03] dark:text-white/[0.03] text-8xl group-hover:text-white/[0.04] transition-all duration-500 ease-in-out">
                                        <a className='size-20'/>
                                    </div>
                                </div>

                                <div  className="group flex items-center relative overflow-hidden p-6 rounded-md shadow dark:shadow-gray-800 bg-gray-50 dark:bg-slate-800 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out bg-white">
                                    <span className="text-indigo-600 group-hover:text-white text-5xl font-semibold transition-all duration-500 ease-in-out">
                                        <a className='size-10'/>
                                    </span>
                                    <div className="flex-1 ms-3">
                                        <h5 className="group-hover:text-white text-xl font-semibold transition-all duration-500 ease-in-out">Real-Time Analytics</h5>
                                        <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-2">Gain insights into learning patterns and performance with real-time analytics.</p>
                                    </div>
                                    <div className="absolute start-1 top-5 text-dark/[0.03] dark:text-white/[0.03] text-8xl group-hover:text-white/[0.04] transition-all duration-500 ease-in-out">
                                        <a className='size-20'/>
                                    </div>
                                </div>

                                <div  className="group flex items-center relative overflow-hidden p-6 rounded-md shadow dark:shadow-gray-800 bg-gray-50 dark:bg-slate-800 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out bg-white">
                                    <span className="text-indigo-600 group-hover:text-white text-5xl font-semibold transition-all duration-500 ease-in-out">
                                        <a className='size-10'/>
                                    </span>
                                    <div className="flex-1 ms-3">
                                        <h5 className="group-hover:text-white text-xl font-semibold transition-all duration-500 ease-in-out">Learn Anywhere, Anytime</h5>
                                        <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-2">Access from anywhere on any device, ensuring that learning can happen on the go.</p>
                                    </div>
                                    <div className="absolute start-1 top-5 text-dark/[0.03] dark:text-white/[0.03] text-8xl group-hover:text-white/[0.04] transition-all duration-500 ease-in-out">
                                        <a className='size-20'/>
                                    </div>
                                </div>
                                  
                            </div>
                        </div>
                    </div>
                </div> */}
                
        {/* <div className="container relative md:mt-24 mt-16">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="lg:col-span-5 md:order-2 order-1">
              <div className="lg:ms-10">
                <div className="bg-white dark:bg-slate-900 p-6 rounded-md shadow dark:shadow-gray-800">
                  <img src='./images/about/img1' alt="" />

                  <div className="mt-8">
                    <form>
                      <div className="grid grid-cols-1">
                        <div className="mb-5">
                          <label className="form-label font-medium">Your Name : <span className="text-red-600">*</span></label>
                          <div className="form-icon relative mt-2">
                            <Icon.User className="size-4 absolute top-3 start-4"></Icon.User>
                            <input type="text" className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Name" name="name" required="" />
                          </div>
                        </div>

                        <div className="mb-5">
                          <label className="form-label font-medium">Your Email : <span className="text-red-600">*</span></label>
                          <div className="form-icon relative mt-2">
                            <Icon.Mail className="size-4 absolute top-3 start-4"></Icon.Mail>
                            <input type="email" className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Email" name="email" required="" />
                          </div>
                        </div>

                        <div className="mb-5">
                          <label className="form-label font-medium">Enter Password : <span className="text-red-600">*</span></label>
                          <div className="form-icon relative mt-2">
                            <Icon.Key className="size-4 absolute top-3 start-4"></Icon.Key>
                            <input type="password" className="form-input ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0" placeholder="Password" required="" />
                          </div>
                        </div>

                        <div className="">
                          <button className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md w-full">Download</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-7 md:order-1 order-2">
              <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Speed up your development <br /> with <span className="text-indigo-600">Techwind.</span></h3>
              <p className="text-slate-400 max-w-xl">You can combine all the Techwind templates into a single one, you can take a component from the Application theme and use it in the Website.</p>

              <ul className="list-none text-slate-400 mt-4">
                <li className="mb-1 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Digital Marketing Solutions for Tomorrow</li>
                <li className="mb-1 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Our Talented & Experienced Marketing Agency</li>
                <li className="mb-1 flex items-center ms-0"><BsCheckCircle className="text-indigo-600 text-base me-2" /> Create your own skin to match your brand</li>
              </ul>

              <div className="mt-4">
                <Link to="/page-aboutus" className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Find Out More <MdKeyboardArrowRight className="text-xl"/></Link>
              </div>
            </div>
          </div>
        </div> */}
      </section>


      {/* Features End */}
      


      {/* Services Start */}
    
      {/* <section className="relative  pt-12">
            <div className="container relative">
                <div className="grid grid-cols-1  text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Our Advanced Solutions</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">Experience cutting-edge video encryption and DRM technologies alongside a powerful LMS platform.</p>
                    <a href="/" className="py-2 m-auto mt-5 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"><i className="uil uil-notes"></i> Get Started</a>

                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-9 pt-4 gap-[30px]">
                    <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hexagon size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path></svg>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                              <img src={encryptionIcon} alt="" className='appIcon'/>

                            </div>
                        </div>

                        <div className="mt-6">
                            <a href="" className="text-lg font-medium group-hover:text-white duration-500">Video Encryption</a>
                            <p className="text-slate-400 group-hover:text-white/50 duration-500 mt-3">Protect your content with robust encryption algorithms.</p>
                        </div>
                    </div>

                    <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hexagon size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto">
                              <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                            </svg>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                            <img src='images/icons/drm.png' alt="" className='appIcon'/>
                            </div>
                        </div>

                        <div className="mt-6">
                            <a href="" className="text-lg font-medium group-hover:text-white duration-500">DRM Integration</a>
                            <p className="text-slate-400 group-hover:text-white/50 duration-500 mt-3">Secure your videos with Digital <br/>Rights Management.</p>
                        </div>
                    </div>

                    <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hexagon size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path></svg>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                              <img src='images/icons/deadline.png' alt="" className='width15'/>
                            </div>
                        </div>

                        <div className="mt-6">
                            <a href="" className="text-lg font-medium group-hover:text-white duration-500">Flexible LMS</a>
                            <p className="text-slate-400 group-hover:text-white/50 duration-500 mt-3">Manage, distribute, and track learning content efficiently.</p>
                        </div>
                    </div>

                    <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hexagon size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path></svg>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                              <img src='images/icons/analys.png' alt="" className='appIcon'/>
                            </div>
                        </div>

                        <div className="mt-6">
                            <a href="" className="text-lg font-medium group-hover:text-white duration-500">Analytics</a>
                            <p className="text-slate-400 group-hover:text-white/50 duration-500 mt-3">Gain insights into viewer engagement <br/>and behavior.</p>
                        </div>
                    </div>
                    
                    <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hexagon size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path></svg>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                              <img src='images/icons/smart-device.png' alt="" className='width15'/>
                            </div>
                        </div>

                        <div className="mt-6">
                            <a href="" className="text-lg font-medium group-hover:text-white duration-500">Multi-Device Compatibility</a>
                            <p className="text-slate-400 group-hover:text-white/50 duration-500 mt-3">Reach audiences seamlessly <br/>across devices.</p>
                        </div>
                    </div>

                    <div className="group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 duration-500 rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hexagon size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path></svg>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white duration-500 text-3xl flex align-middle justify-center items-center">
                              <img src='images/icons/puzzle.png' alt="" className='width15'/>
                            </div>
                        </div>

                        <div className="mt-6">
                            <a href="" className="text-lg font-medium group-hover:text-white duration-500">Customizable Solutions</a>
                            <p className="text-slate-400 group-hover:text-white/50 duration-500 mt-3">Tailor our software to fit your <br/> unique needs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}


        {/* BLog Section start here */}
        <div className="container relative pt-24 pb-16 ">
                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                    <div className="lg:col-span-12 md:col-span-6">
                      <div>
                        <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">Blogs</h6>
                        <h3 className="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold mb-4 md:mb-0">Explore Insights on Latest Updates</h3>
                        <p className="text-slate-400 max-w-xl mt-2 text-justify">It hints at a blend of informative insights, updates, and industry trends that our audience can delve into.</p>
                      </div>
                        <div className="grid lg:grid-cols-3 grid-cols-1 gap-[30px] py-16">
                            <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                {/* <img src={Blog}/> */}
                                <img src='images/index/mixBlog5.jpg' alt="" className=''/>
        
                                <div className="content p-6">
                                    <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Title: Enhancing Live Streaming with VSAT Technology in India </a>
                                    <p className="text-slate-400 mt-3">In today's fast-paced digital world, live streaming has become an integral part of our lives. </p>
                                    
                                    {/* <div className="mt-4">
                                        <Link href="/" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></Link>
                                    </div> */}
                                </div>
                            </div>
        
                            <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                              <img src='images/index/vsatBlog4.avif' alt="" className=''/>
        
                                <div className="content p-6">
                                    <h1 className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Live Interactive Broadcasting Software: Connecting in Real-Time</h1>
                                    {/* <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out"></a> */}
                                    <p className="text-slate-400 mt-3 text-justify">In the digital age, live broadcasting is a powerful tool for   engagement. </p>
                                    
                                    {/* <div className="mt-4">
                                        <Link href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></Link>
                                    </div> */}
                                </div>
                            </div>
        
                            <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                              <img src='images/index/vsatBLog2.jpg' alt="" className=''/>
                                <div className="content p-6">
                                    <a href="" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Transforming the landscape of online education</a>
                                    <p className="text-slate-400 mt-3 text-justify">EduKrypt, a pioneer in educational technology, </p>
                                    
                                    {/* <div className="mt-4">
                                        <Link href="" className="relative inline-block tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 font-normal hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">Read More <i className="uil uil-arrow-right"></i></Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>



    {/* faqs Start here */}
  
    

      <JobFooter/>


      <CookieModal />

     
    </div>
  )
}
